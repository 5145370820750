<template>
<div class="index-warp">
  <header-nav></header-nav>
<!--  轮播图-->
  <div class="banner">
    <img src="../../assets/index/banner.jpg" alt="">
<!--    <div class="input-box">-->
<!--      <div class="input-content fl">-->
<!--        <div class="address">-->
<!--          <img src="../../assets/index/local.png" class="localImg">-->
<!--          <span>广州市</span>-->
<!--          <img src="../../assets/index/icon2.png" class="addressImg">-->
<!--          <span class="border"></span>-->
<!--        </div>-->
<!--        <div class="phone">-->
<!--          <img src="../../assets/index/phone.png" alt="" class="icon1">-->
<!--          <input type="number" class="phone-input" placeholder="请输入手机号定制节税方案">-->
<!--          <img src="../../assets/index/icon1.jpg" alt="" class="icon2">-->
<!--          <span class="border"></span>-->
<!--        </div>-->
<!--        <div class="name">-->
<!--          <img src="../../assets/index/name.png" alt="">-->
<!--          <input type="text" class="name-input" placeholder="如:林女士">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="input-btn fl" @click="show">免费获取</div>-->
<!--    </div>-->
  </div>

  <!--  税务难题-->
  <div id="panelOne" class="panel-one">
    <div class="panel-one-content w">
      <img src="../../assets/index/panelOneBg.png" class="bg">
      <a class="btn" href="#contactCode">
        咨询税筹专家 
      </a>
    </div>
  </div>
<!--  安全可靠的税收解决方案-->
  <div id="panelTwo" class="panel-two">
    <div class="panel-two-content w">
      <span class="title">我们免费为您提供</span>
      <span class="desc">安全可靠的税收解决方案</span>
      <div class="panel-two-box">
        <div class="list" v-for="(item,index) in solutionList" :key="index">
          <div class="item-img">
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <span>{{item.title}}</span>
            <span>{{item.text}}</span>
          </div>
          <a class="btn" href="#contactCode">获取方案</a>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-three">
    <div class="panel-three-content w">
      <div class="item">
          <span class="top"></span>
          <span class="method">方式一</span>
          <span class="title">注册个人独资企业</span>
          <span class="desc">在园区设立个人独资企业，异地经营产生收入后，享受免征企业所得税，
个人所得税从20%降至0.5%-2.1%，以业务合同、资金、发票三流合一
为基础、合规享受税收政策。</span>
        <div class="table-box">
          <span class="table-title">优惠税率:</span>
          <img src="../../assets/index/tableOne.png" alt="">
        </div>
        <div class="tips">
          <span>适用群体:</span>
          <span>缺进项票，利润虚高的企业、网红、律师、高管等高收入群体</span>
        </div>
        <a class="enterDetails" href="#contactCode" style="position: absolute;bottom: 36px">
          了解详情
          <img src="../../assets/index/enter.png" alt="">
        </a>
      </div>
      <div class="item item-two">
        <span class="top"></span>
        <span class="method">方式二</span>
        <span class="title">园区注册合伙企业</span>
        <span class="desc">利用政商园区优惠政策，在园区成立合伙，企业缴纳税金后，可根据缴纳
总额相应比例得到税收奖励，达到减费降负效果。</span>
        <div class="table-box">
          <span class="table-title">奖励比例:</span>
          <img src="../../assets/index/tableTwo.png" alt="">
        </div>
        <div class="tips">
          <span>使用范围:</span>
          <span>投资项目搭建：投资项目、持股平台搭建、主体公司迁移 <br>
股权转让规划：股份减持、股权转让规划</span>
        </div>
        <a class="enterDetails" href="#contactCode" style="position: absolute;bottom: 36px">
          了解详情
          <img src="../../assets/index/enter.png" alt="">
        </a>
      </div>
    </div>
    <div class="tax-ad w">
      <img src="../../assets/index/taxAd.png" alt="">
      <a class="enter btn" href="#contactCode"></a>
    </div>
  </div>

<!--  税筹案例-->
  <div id="panelFour" class="panel-four">
    <img src="../../assets/index/panelFourBg.png" alt="">
    <a class="btn" href="#contactCode"></a>
  </div>
<!--税筹优化流程-->
  <div id="panelFive" class="panel-five">
    <img src="../../assets/index/panelFiveBg.png" alt="">
    <a class="btn" href="#contactCode"></a>
  </div>
<!--  我们的优势-->
  <div class="panel-six" id="panelSix">
      <img src="../../assets/index/panelSixBg.png" alt="">
  </div>

<!--  弹窗-->
  <div class="mask" v-if="showMask">

    <div class="content">
      <img src="../../assets/index/delSpec.png" class="close" @click="close">
        <div class="alart">
          <img src="../../assets/index/tag.png" class="tag">
          <img src="../../assets/index/avatar.png" class="avatar">
          <span class="title">扫码了解安税师</span>
          <img src="../../assets/index/code.png" class="code">
          <span class="text">安全节税，找安税师</span>
          <span class="desc">联系方式:18664785766    客服邮箱:csl@fagongzi.com</span>
        </div>
    </div>
  </div>
<!--  尾部-->
  <public-footer></public-footer>
</div>
</template>

<script>
import headerNav from "@/components/headerNav/headerNav";
import publicFooter from "@/components/publicFooter/publicFooter"
export default {
name: "index",
  components:{
    headerNav,
    publicFooter
  },
  data(){
    return{
      showMask:false,
      solutionList:[
        {
          id:1,
          title:'工资收入型',
          text:"(个人所得税) 筹划方案",
          img:require('../../assets/index/bg1.png')
        },
        {
          id:1,
          title:'劳务收入型',
          text:"(企业所得税) 筹划方案",
          img:require('../../assets/index/bg2.png')
        },
        {
          id:1,
          title:'投资收益型',
          text:"(分红个税) 筹划方案",
          img:require('../../assets/index/bg3.png')
        },
        {
          id:1,
          title:'企业利润型',
          text:"(个人所得税) 筹划方案",
          img:require('../../assets/index/bg4.png')
        },
        {
          id:1,
          title:'贸易采购型',
          text:"(企业所得税) 筹划方案",
          img:require('../../assets/index/bg5.png')
        },
        {
          id:1,
          title:'增值税筹划方案',
          text:"享优惠园区纳税返还",
          img:require('../../assets/index/bg6.png')
        }
      ]
    }
  },
  mounted(){
      var id=localStorage.getItem('id')
      this.$nextTick(() => {
        document.querySelector("#"+id).scrollIntoView(true)
      })
  },
  methods:{
    show(){
      this.showMask = true
    },
    close(){
      this.showMask = false
    }
  }
}
</script>

<style scoped>
.btn{
  display: block;
}
.index-warp{
  padding-top: 80px;
}
.banner{
  width: 100%;
  height: 500px;
  position: relative;
  min-width: 1200px;
}
.banner img{
  width: 100%;
  height: 100%;
}
.input-box{
  width: 910px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);
}
.input-box>.input-content{
  width: 776px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
}
.input-content>.address{
  display: flex;
  align-items: center;
  width: 154px;
  height: 100%;
  justify-content: space-around;
}

.input-content>.address>.localImg{
  width: 14px;
  height: 17px;
}
.input-content>span{
  color: #333;
  font-size: 16px;
  font-weight: 500;
}
.input-content>.address>.addressImg{
  width: 10px;
  height: 6px;
}

.input-content>.phone{
  display: flex;
  align-items: center;
  width: 310px;
  height: 100%;
  justify-content: space-around;
}
.input-content>.phone>.icon1{
  width: 16px;
  height: 16px;
}
.input-content>.phone>.phone-input{
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  outline: none;
  width: 200px;
}
.input-content>.phone>.icon2{
  width: 16px;
  height: 20px;
}
.input-box>.input-btn{
  background-color: #00964E;
  color: white;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  width: 134px;
  cursor: pointer;
}

.input-content>.name{
  display: flex;
  align-items: center;
  height: 100%;
}
.input-content>.name>img{
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.input-content>.name>.name-input{
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  outline: none;
  width: 200px;
}

.panel-one{
  height: 560px;
}
.panel-one-content{
  text-align: center;
  padding-top: 40px;
}
.panel-one-content .bg{
  width: 1014px;
  height: 410px;
  margin-bottom: 20px;
}
.panel-one-content .btn{
  width: 266px;
  height: 60px;
  background-image: url("../../assets/index/btnBg.png");
  background-size: 100% 100%;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.panel-two{
  width: 100%;
  height: 648px;
  min-width: 1200px;
  background-image: url("../../assets/index/panelTwoBg.png");
  background-size: 100% 100%;
  text-align: center;
  padding-top: 40px;
}
.panel-two-content>.title{
  display: block;
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 18px;
  opacity: 0.8;
  letter-spacing: 14px;
}
.panel-two-content>.desc{
  display: block;
  font-size: 36px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 4px;
  margin-bottom: 50px;
}
.panel-two-box{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.panel-two-box .list {
  width: 400px;
  height: 164px;
  position: relative;
}

.panel-two-box .list .item-img{
  width: 400px;
  height: 164px;
  position: absolute;
  left: 0;
  top: 0;
}
.panel-two-box .list .item-img img{
  width: 100%;
  height: 100%;
}
.panel-two-box .list .text{
  position: absolute;
  left: 42px;
  top: 50%;
  transform: translateY(-50%);
}
.panel-two-box .list .text span{
  display: block;
  color: #ffffff;
  text-align: left;
}
.panel-two-box .list .text span:nth-child(1){
  font-size: 24px;
  margin-bottom: 20px;
}
.panel-two-box .list .text span:nth-child(2){
  font-size: 18px;
}
.panel-two-box .list .btn{
  position: absolute;
  width: 110px;
  height: 38px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(58,86,138,0.3);
  font-size: 18px;
  color: #5E7AAF;
  line-height: 38px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.panel-three{
  margin-top: -90px;
}
.panel-three-content{
  display: flex;
  justify-content: space-between;
  
}
.panel-three-content>.item{
  width: 588px;
  padding: 0 40px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
  position: relative;
  padding-top: 38px;
  padding-bottom: 80px;
}
.panel-three-content>.item>.top{
  background-color: #5CB8FA;
  height: 6px;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  top:0;
}
.panel-three-content>.item>.method{
  width: 100px;
  height: 36px;
  background: #5cb8fa;
  border-radius: 6px;
  text-align: center;
  line-height: 36px;
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 0;
}
.panel-three-content>.item>.title{
  color: #4FA0E5;
  font-size: 24px;
  display: block;
}
.panel-three-content>.item>.desc{
  color: #999999;
  font-size: 16px;
  margin: 25px 0;
  display: block;
}
.table-box>.table-title{
  color: #333;
  font-size: 16px;
  font-weight: 700;
}
.table-box>img{
  width: 532px;
  height: 122px;
  margin-top: 13px;
  margin-bottom: 26px;
}
.panel-three-content>.item>.tips span:nth-child(1){
  color: #333;
  font-size: 16px;
  display: block;
  font-weight: 700;
}
.panel-three-content>.item>.tips span:nth-child(2){
  color: #999999;
  font-size: 16px;
  display: block;
  margin-top: 12px;
  margin-bottom: 28px;
}
.enterDetails{
  display: block;
  width: 129px;
  height: 33px;
  border: 1px solid #fc7f29;
  border-radius: 2px;
  text-align: center;
  line-height: 33px;
  color:#fc7f29 ;
}
.enterDetails img{
  width: 15px;
  height: 13px;
}

.item-two>.top,.item-two>.method{
  background-color: #FF9F02!important;
}
.item-two>.title{
  color: #FF9F02!important;
}
.item-two>.table-box>img{
  width: 460px!important;
  height: 162px!important;
}
.tax-ad{
  height: 90px;
  position: relative;
  margin-top: 36px;
  margin-bottom: 62px;
}
.tax-ad img{
  width: 100%;
  height: 100%;
}
.tax-ad .enter{
  position: absolute;
  cursor: pointer;
  width: 150px;
  height: 40px;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
}

.panel-four{
  position: relative;
  min-width: 1200px;
  position: relative;
}
.panel-four img{
  width: 100%;
  /*height: 695px;*/
}
.panel-four .btn{
  width: 16.666%;
  height: 7.2%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 45px;
  cursor: pointer;
}

.panel-five{
  position: relative;
  min-width: 1200px;
  position: relative;
}
.panel-five img{
  width: 100%;
  /*height: 294px;*/
}
.panel-five .btn{
  width: 7.70%;
  height: 13%;
  position: absolute;
  left:19.06%;
  bottom: 60px;
  cursor: pointer;
}


.panel-six{
  position: relative;
  min-width: 1200px;
  position: relative;
}
.panel-six img{
  width: 100%;
}

.mask{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}
.mask>.content{
  width: 400px;
  height: 400px;
  background-image: url("../../assets/index/maskBg.png");
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.mask>.content>.close{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.mask>.content>.alart{
  width: 338px;
  height: 340px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 35px;
  position: relative;
}
.tag{
  width: 30px;
  height: 50px;
  position: absolute;
  left: 15px;
  top: 0;
}
.avatar{
  width: 78px;
  height: 78px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -35px;
}
.alart{
  padding-top: 55px;
}
.alart span{
  display: block;
  text-align: center;
}
.alart .title{
  font-size: 24px;
  color: #000000;
}
.alart .code{
  width: 148px;
  height: 148px;
  margin:0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.alart .text{
  color: #666666;
  font-size: 16px;
  margin-bottom: 10px;
}
.alart .desc{
  font-size: 12px;
  color: #666666;
}
.border{
  display: inline-block;
  width: 2px;
  height: 15px;
  background-color: #F1F1F5;
}
</style>